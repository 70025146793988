$font-family: Inter, Verdana, Arial, Helvetica, sans-serif;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-extra-bold: 700;

$cc-black: #000000 !default;
$cc-white: #ffffff !default;
$cc-red: #EC1C24 !default;
$cc-blue: #007599 !default;
$cc-light-gray: #F3F3F3 !default;
$cc-dark-gray: #737373 !default;
