.product-summary {
  position: relative;

  .product-wrapper {
    border: 1px solid transparent;
    // transition: border .25s linear .25s;
    background-color: rgb(255, 255, 255);
    // z-index: 0;
    position: relative;
    .product-image-container {

    }

    .product-thumb-container {
      display: block;
      padding: 5px 10px;
      height: 0px;
      // transition: height .25s linear .25s;
      .product-slider {
        // transition: opacity .25s linear 0s;
        opacity: 0;
        .thumb {
          width: 50px;
          &:hover {
            // position: absolute;
            // bottom: 0;
            // height: 2px;
            // background-color: $cc-black;
          }
        }
      }
    }

    .price-overlay {
      position: relative;
      padding: 0px 10px;
      .price {
        font-family: interRegular;
        font-size: 16px;
        line-height: 16px;
        font-weight: 100;
        padding: 6px;
        background-color: #fff;
        position: absolute;
        top: -28px;
        transition: .25s linear;
      }
      &.sale {
        .price {
          // text-decoration: line-through;
          .sale-price {
            color: $cc-red;
          }
        }
      }
    }

    .product-info-container {
      padding: 6px 10px;
      .product-headline {
        font-family: interBold;
        font-size: 16px;
        line-height: 17px;
        font-weight: 100;
        margin-bottom: 5px;
      }
      .product-subhead {
        font-family: interRegular;
        font-size: 16px;
        line-height: 16px;
        font-weight: 100;
      }
    }
  }

  &:hover {
    z-index: 1;
    .product-wrapper {
      border: 1px solid $cc-black;
      // transition: border .25s linear 0s;
      // position: absolute;
      .price-overlay {
        .price {
          top: -38px;
          transition: .25s linear 0s;
        }
      }
      .product-thumb-container {
        // height: 70px;
        // transition: height .25s linear 0s;
        .product-slider {
          // transition: opacity .25s linear .25s;
          // opacity: 1;
          display: inline-flex;
          overflow: hidden;
        }
      }
    }
  }

}
