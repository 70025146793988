.image-slider {
    padding: 0 0 5px;
    .slick-slider {
      .slick-list {
        overflow: hidden !important;
      }
    }

    .slides {

        img {
            height: auto;
            width: 100%;
            padding: 0px;
        }
    }

    // Fix selectable bug.
    &.is-admin {
        &:before {
            display: block;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 40px;
            position: absolute;
            z-index: 1;
        }
    }
}
