.container-fluid {
  padding: 0px !important;
  overflow: hidden;
  .hero {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
    background-position: center center;
  }
  .simple-hero {
    img {
      width: 100%;
    }
  }
}
