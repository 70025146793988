.checked-list {
  li {
    padding-left:0.5em
  }
  li::marker {
    color: black;
    content: "✓";
  }

}
  