.newsletter {
  .subscribe-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .subscribe {
      width: 352px;
      input {
        border: 1px solid $cc-black;
        font-size: 18px;
        height: 64px;
        padding: 1em;
        width: 352px;
      }
    }
  }
}
