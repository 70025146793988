.product-grid {
  padding-bottom: 60px;
  &.sidebar-active {
    .product-col {
      transition: width 500ms ease;
      // width: 33.33333333%;
    }
  }
  .product-col {
    padding-left: 1px;
    padding-right: 1px;
    transition: width 500ms ease;
    @media (max-width: 575px) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
