

.cc-btn {
  font-family: interBold;
  font-size: 40px;
  line-height: 40px;
  font-weight: 100;
  color: $cc-white;
  background: $cc-black;
  text-transform: uppercase;
  letter-spacing: 0.000001px;
  // max-width: 352px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 0px;
  border: none;
  cursor: pointer;
  &:hover {
    color: $cc-red;
    background: $cc-black;
    border: none;
  }
  &.callout-btn {

  }
}

.filter-sort-btn {
  display: flex;
  align-items: center;
  font-family: interBold;
  font-size: 16px;
  line-height: 18px;
  font-weight: 100;
  color: $cc-white;
  background: $cc-black;
  text-transform: none;
  width: 200px;
  text-align: left;
  padding: 16px;
  border-radius: 0px;
  &:after {
    content: '';
    display: inline-block;
    background-image: url('../block_templates/images/icons/filter_sort_icon.svg');
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }
  &:hover {
    background-color: $cc-dark-gray;
    color: $cc-white;
  }
  &.light {
    border: 1px solid $cc-black;
    padding: 14px;
    color: $cc-black;
    background: $cc-white;
    &:after {
      content: '';
      background-image: url('../block_templates/images/icons/filter_sort_dark_icon.svg');
    }
    &:hover {
      background-color: $cc-light-gray;
    }
  }
  @media (max-width: 767px) {
    width: auto;
  }
}

.subscribe-btn {
  color: $cc-white !important;
  text-decoration: none !important;
  &:hover {
    background-color: $cc-white !important;
    text-decoration: none !important;
    color: $cc-black !important;
  }
  &.light {
    color: $cc-black !important;
    &:hover {
      background-color: $cc-black !important;
      text-decoration: none !important;
      color: $cc-white !important;
    }
  }
}

.pro-team-btn {
  color: $cc-white !important;
}

.cart-btn, .subscribe-btn, .apply-btn, .pro-team-btn {
  display: flex;
  align-items: center;
  font-family: bebasNeue;
  font-size: 16px;
  line-height: 16px;
  font-weight: 100;
  color: $cc-white;
  background: $cc-black;
  text-transform: uppercase;
  text-decoration: none;
  // width: 352px;
  text-align: left;
  letter-spacing: 1px;
  padding: 15px 37px 13px;
  border-radius: 0px;
  cursor: pointer;
  &:after {
    content: '>';
    display: inline-block;
    // background-image: url('../block_templates/images/icons/arrow_btn_icon.svg');
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
  &:hover {
    background-color: $cc-dark-gray;
    text-decoration: none !important;
    color: $cc-white;
  }
  &.inactive {
    background-color: $cc-dark-gray;
    color: rgba(255,255,255,.5);
    cursor: none;
    pointer-events: none;
    &:after {
      opacity: 0.5;
    }
  }
  &.light {
    background-color: $cc-white;
    color: $cc-black;
    a {
      color: $cc-black;
    }
  }
}

.heart-icon {
  background-image: url('../block_templates/images/icons/heart_icon.svg');
  background-color: $cc-white;
  background-repeat: no-repeat;
  min-width: 50px;
  width: 50px;
  height: 50px;
  border: none;
  &.active {
    background-image: url('../block_templates/images/icons/heart_active_icon.svg');
  }
  &:hover {
    background-image: url('../block_templates/images/icons/heart_active_icon.svg');
  }
}

.see-more {
  font-family: interRegularItalic;
  font-size: 16px;
  line-height: 18px;
  font-weight: 100;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0px 10px;
  color: $cc-dark-gray;
  &:before {
    content: '[';
  }
  &:after {
    content: ']';
  }
  &:hover {
    text-decoration: none;
    color: $cc-black;
  }
}
