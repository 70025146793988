#generic {
  .slick-track
  {
    display: flex !important;
  }

  .slick-slide
  {
    height: inherit !important;
  }
  .generic-slider {
    position: relative;
    height: 100%;

    .product-wrapper {
      border: 1px solid transparent;
      background-color: rgb(255, 255, 255);
      position: relative;
      height: 100%;
      .product-image-container {

      }

      .product-thumb-container {
        display: block;
        padding: 5px 10px;
        height: 70px;
        .product-slider {
          opacity: 0;
          .thumb {
            width: 50px;
            &:hover {
              position: relative;
              &:after {
                content: '';
                position: absolute;
                bottom: 0px;
                height: 2px;
                width: 100%;
                background-color: $cc-black;
              }
            }
            img {
              margin-bottom: 8px;
            }
          }
        }
      }

      .price-overlay {
        position: relative;
        padding: 0px 10px;
        .price {
          font-family: interRegular;
          font-size: 16px;
          line-height: 16px;
          font-weight: 100;
          padding: 6px;
          background-color: #fff;
          position: absolute;
          top: -28px;
          transition: .25s linear;
        }
      }

      .product-info-container {
        padding: 6px 10px;
        .product-headline {
          font-family: interBold;
          font-size: 16px;
          line-height: 17px;
          font-weight: 100;
          margin-bottom: 5px;
        }
        .product-subhead {
          font-family: interRegular;
          font-size: 16px;
          line-height: 16px;
          font-weight: 100;
        }
      }
    }

    // &:hover {
    //   z-index: 1;
    //   .product-wrapper {
    //     position: absolute;
    //     .price-overlay {
    //       .price {
    //         top: -38px;
    //         transition: .25s linear 0s;
    //       }
    //     }
    //     .product-thumb-container {
    //       height: 70px;
    //       .product-slider {
    //         opacity: 1;
    //       }
    //     }
    //   }
    // }

    &:hover {
      z-index: 1;
      .product-wrapper {
        // position: absolute;
        border: 1px solid $cc-black;
        .price-overlay {
          .price {
            top: -62px;
            transition: .25s linear 0s;
          }
        }
        .product-thumb-container {
          height: 70px;
          .product-slider {
            opacity: 1;
          }
        }
      }
    }

  }
}
