.chl-block {
  .callout-h1 {
    font-family: interBold !important;
    font-size: 60px;
    line-height: 54px;
    font-weight: 100;
    color: $cc-white;
    text-transform: uppercase;
    @media (max-width: 767.98px) {
      font-size: 42px;
      line-height: 37px;
    }
  }

  .callout-h2 {
    font-family: interRegularItalic !important;
    font-size: 65px;
    line-height: 58px;
    font-weight: 100;
    color: $cc-white;
    text-transform: uppercase;
    @media (max-width: 767.98px) {
      font-size: 36px;
      line-height: 32px;
    }
  }

  .callout-p {
    font-family: interRegular;
    font-size: 20px;
    line-height: 25px;
    font-weight: 100;
    color: $cc-white;
    @media (max-width: 767.98px) {
      font-size: 18px;
      line-height: 25px;
    }
  }
  .callout-btn {
    // width: 352px;
    color: $cc-black;
    background: $cc-white;
    border: none !important;
    display: inline-block;
    text-decoration: none;
    &:hover {
      color: $cc-red;
      background: $cc-white;
      border: none;
      text-decoration: none !important;
    }
  }
}
