.form-headline {
    font-size: 2.617924em;
    line-height: 1.214;
    letter-spacing: -1px;
    font-family: $font-family;
    color: #2a3533;
    margin-top: 20px;
}

.form-subheadline {
    font-size: 1.617924em;
    line-height: 1.214;
    letter-spacing: -1px;
    font-family: $font-family;
    color: #2a3533;
}

.forgot-password {
    color: #107f67;
    text-decoration: underline;
    font-family: $font-family;

}

.error-message {
    color: #FF0000;
    font-family: $font-family;
}

.input-checkbox {
    margin-right: 5px;
}
form {
  position: relative;
  .input-text {
    border: none;
    font-size: 18px;
    height: 50px;
    padding: 1em;
    background: $cc-light-gray;
    width: 100%;
    &:focus {
      outline-width: 1px;
      outline-style: solid;
      outline-color: black;
      background: $cc-white;
    }
  }
}

.login-button, .forgot-password-button, .create-account-button {
  border: 1px solid $cc-black;
  border-radius: 0;
  background: none;
  background-color: $cc-black;
  border-color: $cc-black;
  color: #ffffff !important;
  cursor: pointer;
  padding: 15px;
  text-decoration: none;
  font-weight: 600;
  text-shadow: none;
  display: inline-block;
  -webkit-appearance: none;
  word-break: break-all;
  font-size: 100%;
  margin: 0;
  font-family: $font-family;
  line-height: 1.618;
  text-rendering: optimizeLegibility;
  text-transform: uppercase;
  &:hover {
    background-color: $cc-white;
    color: $cc-black !important;
    text-decoration: none !important;
  }
}

.learn-more {
  background-color: $cc-light-gray;
}
