
.chl-block {
  margin-top: 0px;
  body {
    font-family: interRegular, sans-serif;
  }

  p {
    font-family: interRegular;
    font-size: 16px;
    line-height: 27px;
    font-weight: 100;
    color: $cc-black;
    strong, b {
      font-family: interBold;
    }
    a {
      &:hover {
        color: $cc-white;
        background-color: $cc-black;
      }
    }
  }

  a {
    color: $cc-black;
    &:hover {
      text-decoration: underline;
    }

    &.link-unstyled {
      color: inherit;
      text-decoration: none;
    }
  }

  h1 {
    font-family: interBold;
    font-size: 42px;
    line-height: 42px;
    font-weight: 100;
    text-transform: uppercase;
    color: $cc-black;
    @media (max-width: 991px) {
      font-size: 42px;
      line-height: 37px;
    }
  }

  h2 {
    // font-family: interRegularItalic;
    // font-size: 38px;
    // line-height: 38px;
    // font-weight: 100;
    // text-transform: uppercase;
    // color: $cc-black;
    font-family: montserratBlack;
    font-size: 26px;
    line-height: 26px;
    font-weight: 100;
    text-transform: uppercase;
    color: $cc-black;
    // @media (max-width: 991px) {
    //   font-size: 36px;
    //   line-height: 32px;
    // }
    // &.h1 {
    //   font-family: interBold;
    //   font-size: 38px;
    //   line-height: 38px;
    //   font-weight: 100;
    //   text-transform: uppercase;
    // }
  }

  h3 {
    font-family: interRegularItalic;
    font-size: 33px;
    line-height: 33px;
    font-weight: 100;
    text-transform: uppercase;
    color: $cc-black;
    @media (max-width: 991px) {
      font-size: 24px;
      line-height: 21px;
    }
    &.h1 {
      font-family: interBold;
      font-size: 33px;
      line-height: 33px;
      font-weight: 100;
      text-transform: uppercase;
    }
  }

  h4 {
    font-family: interBold;
    font-size: 21px;
    line-height: 21px;
    font-weight: 100;
    text-transform: uppercase;
    color: $cc-black;
    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  h5 {
    font-family: interBold;
    font-size: 18px;
    line-height: 18px;
    font-weight: 100;
    color: $cc-black;
    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  h6 {
    font-family: interRegular;
    font-size: 18px;
    line-height: 18px;
    font-weight: 100;
    color: $cc-black;
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 27px;
    }
  }
  .has-black-color {
    color: #000000;
  }
  .has-white-color {
    color: #ffffff;
  }
  .has-red-color {
    color: #ec1c24;
  }
  .has-blue-color {
    color: #007599;
  }
  .has-light-grey-color {
    color: #f3f3f3;
  }
  .has-dark-grey-color {
    color: #737373;
  }
}
