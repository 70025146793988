.wp-block-post-content {
  $var-gutter-x: --#{$prefix}gutter-x;
  
  > :not(.container):not(.container-fluid) {
    margin-left: auto;
    margin-right: auto;
    #{$var-gutter-x}: #{$container-padding-x};
  }
    
  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint) {
      > :not(.container):not(.container-fluid):not(.row) {
        max-width: calc($container-max-width - var($var-gutter-x));
      }
      
      > .row {
        max-width: $container-max-width;
      }
    }
  }
  
  > * + * {
    margin-top: var($var-gutter-x);
//    margin-bottom: 0;
  }
}
