@font-face {
  font-family: interRegular;
  src: url('../assets/fonts/Inter_Regular_400.otf');
}
@font-face {
  font-family: interRegularItalic;
  src: url('../assets/fonts/Inter_Italic_400.otf');
}
@font-face {
  font-family: interBold;
  src: url('../assets/fonts/Inter_Bold_700.otf');
}
@font-face {
  font-family: interBoldItalic;
  src: url('../assets/fonts/Inter_Bold_Italic_700.otf');
}
@font-face {
  font-family: bebasNeue;
  src: url('../assets/fonts/BebasNeue.otf');
}
@font-face {
  font-family: montserratBlack;
  src: url('../assets/fonts/Montserrat-Black.otf');
}
@font-face {
  font-family: montserratBlackItalic;
  src: url('../assets/fonts/Montserrat-BlackItalic.otf');
}
@font-face {
  font-family: montserratBold;
  src: url('../assets/fonts/Montserrat-Bold.otf');
}
@font-face {
  font-family: montserratBoldItalic;
  src: url('../assets/fonts/Montserrat-BoldItalic.otf');
}

body {
  font-family: interRegular, sans-serif;
}

p {
  font-family: interRegular;
  font-size: 16px;
  line-height: 27px;
  font-weight: 100;
  color: $cc-black;
  strong, b {
    font-family: interBold;
  }
  a {
    color: $cc-black;
    &:hover {
      color: $cc-white;
      background-color: $cc-black;
    }
  }
}

a {
  color: $cc-black;
  &:hover {
    text-decoration: underline;
  }

  &.link-unstyled {
    color: inherit;
    text-decoration: none;
  }
}

h1 {
  font-family: interBold;
  font-size: 42px;
  line-height: 42px;
  font-weight: 100;
  text-transform: uppercase;
  color: $cc-black;
  @media (max-width: 991px) {
    font-size: 42px;
    line-height: 37px;
  }
}

h2 {
  font-family: montserratBlack;
  font-size: 26px;
  line-height: 26px;
  font-weight: 100;
  text-transform: uppercase;
  color: $cc-black;
  // font-family: interRegularItalic;
  // font-size: 38px;
  // line-height: 38px;
  // font-weight: 100;
  // text-transform: uppercase;
  // color: $cc-black;
  // @media (max-width: 991px) {
  //   font-size: 32px;
  //   line-height: 36px;
  // }
}

h3 {
  font-family: interRegularItalic;
  font-size: 33px;
  line-height: 33px;
  font-weight: 100;
  text-transform: uppercase;
  color: $cc-black;
  @media (max-width: 991px) {
    font-size: 24px;
    line-height: 21px;
  }
}

h4 {
  font-family: interBold;
  font-size: 21px;
  line-height: 21px;
  font-weight: 100;
  text-transform: uppercase;
  color: $cc-black;
  @media (max-width: 991px) {
    font-size: 20px;
    line-height: 20px;
  }
}

h5 {
  font-family: interBold;
  font-size: 18px;
  line-height: 18px;
  font-weight: 100;
  color: $cc-black;
  @media (max-width: 991px) {
    font-size: 20px;
    line-height: 30px;
  }
}

h6 {
  font-family: interRegular;
  font-size: 18px;
  line-height: 18px;
  font-weight: 100;
  color: $cc-black;
  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 27px;
  }
}
