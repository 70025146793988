.wysiwyg {
  // text-align: left;
  padding-top: 58px;

  h1, h2 {
    margin-bottom: 0.5rem;
  }

  @include media-breakpoint-up(sm) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  img {
    height: auto;
    width: 100%;
  }
}
