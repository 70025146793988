
.slick-slider {
  // padding-top: 48px;
  .slick-arrow {
    position: absolute;
    top: -35px;
    width: 48px;
    height: 48px;
    &.slick-prev {
      right: 114px;
      left: unset;
      &:before {
        content: '';
        width: 48px;
        height: 48px;
        display: block;
        background-image: url('../block_templates/images/icons/slick_slider_left_arrow.svg');
        background-size: 48px 48px;
        background-repeat: no-repeat;
      }
      @media (max-width: 991px) {
        right: 50px;
      }
    }
    &.slick-next {
      right: 60px;
      &:before {
        content: '';
        width: 48px;
        height: 48px;
        display: block;
        background-image: url('../block_templates/images/icons/slick_slider_right_arrow.svg');
        background-size: 48px 48px;
        background-repeat: no-repeat;
      }
      @media (max-width: 991px) {
        right: 0px;
      }
    }
  }
}
.dark {
  .slick-slider {
    .slick-arrow {
      &.slick-prev {
        &:before {
          content: '';
          background-image: url('../block_templates/images/icons/slick_slider_left_light_arrow.svg');
        }
      }
      &.slick-next {
        &:before {
          content: '';
          background-image: url('../block_templates/images/icons/slick_slider_right_light_arrow.svg');
        }
      }
    }
  }
}

.slick-slider {
  &.slides {
    margin: 0 40px;
    .slick-arrow {
      top: 50%;
      &.slick-prev {
        left: -50px !important;
      }
      &.slick-next {
        right: -50px !important;
      }
    }
  }
}
