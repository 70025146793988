.pro-team-block {
  padding: 80px 0px !important;
  .pro-team-content-wrapper {
    position: relative;
    &:after {
      content: '';
      display: block;
      height: 100%;
      width: 100vw;
      background: white;
      position: absolute;
      right: 0%;
      top: 0;
      z-index: 1;
    }
    .pro-team-content {
      z-index: 2;
      max-width: 350px;
    }
  }
  .pro-team-slider {
    .slick-arrow.slick-prev {
      right: 30%;
      left: unset;
    }
    .slick-arrow.slick-next {
      right: 25%;
    }
  }
  .slick-list {
    // overflow: hidden !important;
    .slick-track {
      .pro-team-wrapper {
        .pro-image-container {
          img {
            margin-bottom: 0px;
          }
        }
        .pro-team-info {
          .pro-title {
            font-family: montserratBold;
          }
          .pro-sub-title {
            line-height: 20px;
          }
        }
      }
    }
  }
}
